<template>
  <div class="ac1">
    <div class="banner">
      <img src="/imgs/b1.jpg" alt="" />
    </div>
    <div class="container">
      <!-- 标题 -->
      <div class="at-title">
        <h2>行业资讯></h2>
      </div>

      <!-- 循环项 -->
      <div
        class="at-item"
        v-for="(item, index) in this.myData"
        :key="index"
        @click="gopay()"
      >
        <!-- 右侧信息 -->
        <div class="item-r">
          <!-- 标题 -->
          <div class="item-title">{{ item.title }}</div>
          <!-- 文本 -->
          <p>
            {{ item.jj }}
          </p>
          <!-- 信息层 -->
          <div class="item-info">
            <span></span>
            <span>付费阅读：2元/条</span>
          </div>
        </div>
      </div>
    </div>
    <modal
      title="请付款阅读"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img src="/imgs/pay.png" />
        <h2>微信支付</h2>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "./../components/Modal.vue";
export default {
  name: "ac1",
  components: {
    Modal,
  },
  data() {
    return {
      href: "index.html",
      ac1: [],
      ac2: [],
      ac3: [],
      ac: [],
      myData: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init1();
    document.title = "游游新媒（北京）文化传播有限公司";
  },
  methods: {
    init1() {
      this.axios.get("/mock/ac.json").then((res) => {
        const data = res.data;
        // 咨询区块1
        this.myData = data.slice(0, 10);
      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // console.log('1')
      // alert("资讯每篇2元，点击确定购买");
      // this.showModal = true;
      alert("资讯每条2元，点击确定购买");
      this.showModal = true;
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      //   console.log(this.showModal);
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("资讯每条2元，点击确定登录购买");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac1 {
  .banner {
    width: 100%;
    height: 450px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .container {
    width: 980px;
    //   标题
    .at-title {
      width: 100%;
      height: 40px;
      box-sizing: border-box;
      margin-bottom: 20px;
      margin: 20px auto;
      h2 {
        font-size: 16px;
        color: $colorZ;
        font-weight: 400px;
      }
    }
    //   循环项
    .at-item {
      width: 100%;
      height: 200px;
      @include flex();
      box-sizing: border-box;
      transition: all 0.3s;
      margin: 28px auto;
      border-bottom: 1px dashed #ccc;
      &:hover {
        background: #eee;
      }

      // 右侧
      .item-r {
        width: 90%;
        height: 100%;
        //   background-color: #ff6600;
        padding: 20px;
        box-sizing: border-box;
        // margin-left: 30px;
        text-align: left;
        //   标题
        .item-title {
          font-size: 16px;
          font-weight: bold;
          margin: 10px 0;
          transition: all 0.3s;
          color: #555;
        }
        //   文本
        p {
          font-size: 14px;
          // text-indent: 30px;
          color: #7c7c7c;
          line-height: 25px;
          margin: 15px auto;
          text-align: left;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
        //   信息层
        .item-info {
          width: 100%;
          height: 40px;
          @include flex();
          span {
            font-size: 13px;
            color: #666;
            i {
              margin-right: 10px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
